<template>
  <div class="g181-container">
    <el-form inline>
      <el-form-item>
        <el-button @click="handleSave">保存</el-button>
      </el-form-item>
    </el-form>
    <div ref="excelContainer" class="excel-container">
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import config from '@/config'

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      month: 'getMonth',
      date: 'getDate',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    async initSpread () {
      if (!this.fileData) {
        this.fileData = await axios.get('/excel/g181.xlsx', {
          responseType: 'blob'
        })
      }
      const meterageListResult = await axios.get(`${config.restHost}/g181/defaultList`, {
        params: {
          tenderGuid: this.tenderGuid,
          year: this.year,
          month: this.month,
          date: this.date
        }
      })
      this.meterageList = meterageListResult.data

      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(this.fileData.data, json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)
        this.workbook.getActiveSheet().setRowCount(this.meterageList.length + 1)

        this.workbook.getActiveSheet().setArray(1, 0, this.toExcelArray(this.meterageList))
      })
    },
    toExcelArray (list) {
      return list.map(row => {
        return [
          row.meterageDisplayCode,
          row.meterageName,
          row.unit,
          row.price,
          row.pictureNum,
          row.pictureAmount,
          row.currentYearNum,
          row.currentMonthNum
        ]
      })
    },
    handleSave () {
      const json = this.workbook.getActiveSheet().toJSON().data.dataTable
      const resultArray = []

      Object.keys(json).map(key => {
        const arr = json[key]
        const listItem = this.meterageList.find(item => item.meterageDisplayCode === arr['0']?.value)
        if (listItem) {
          resultArray.push({
            meterageListGuid: listItem?.meterageListGuid,
            tenderGuid: this.tenderGuid,
            planStatExaminationGuid: this.planStatExaminationGuid,
            currentDateNum: arr['8']?.value,
            year: this.year,
            month: this.month,
            date: this.date
          })
        }
      })

      if (resultArray.some(item => item.currentDateNum && isNaN(item.currentDateNum))) {
        this.$message.error('输入数据存在问题，请检查')
        return
      }

      const data = resultArray.filter(item => item.currentDateNum).map(item => ({
        ...item,
        currentDateNum: parseFloat(item.currentDateNum)
      }))

      if (data.length === 0) {
        this.$message.warning('您没有输入任何有效数据！')
        return
      }

      axios.post(`${config.restHost}/g181/batchAdd`, data).then(res => {
        console.log(res)
        this.$message({
          type: 'success',
          message: '操作成功！'
        })
      }).catch(err => {
        console.log(err)
        this.$message({
          type: 'error',
          message: '操作失败！'
        })
      }).finally(_ => {
        this.initSpread()
      })
    }
  },
  mounted () {
    this.initSpread()
  }
}
</script>

<style lang="scss" scoped>
.g181-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.excel-container {
  flex: 1;
  width: 100%;
  overflow: hidden;
}
</style>
